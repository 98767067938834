import React from 'react';
import cx from 'classnames';
import { Base } from './Base';
import style from './style.module.css';

interface Props {
  className?: string;
  children?: React.ReactNode;
  to?: any;
  disabled?: boolean;
}
export const BaseRed = ({ className, disabled, ...other }: Props) => {
  const defaultClasses = cx('f12 fw7 red link lh-copy pointer underline-hover', className, {
    [style.transforms]: !disabled,
    alto: disabled,
    'not-allowed': disabled,
  });
  return <Base className={defaultClasses} {...other} />;
};
