import React from 'react';
import cx from 'classnames';
import { Base } from './Base';

interface Props {
  className?: string;
  children?: React.ReactNode;
  to?: any;
  disabled?: boolean;
  onClick?: (value: any) => void;
  resetClasses?: boolean;
  title?: string;
  tabIndex?: number;
  render?: boolean;
}
export const BaseBlack = ({
  className,
  disabled,
  resetClasses,
  render = true,
  ...other
}: Props) => {
  if (!render) {
    return null;
  }
  const classes = resetClasses ? '' : 'f14 fw6 ttu bn white pointer underline';
  const defaultClasses = cx(classes, className);
  return <Base className={defaultClasses} {...other} />;
};
