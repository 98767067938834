import React from 'react';
import cx from 'classnames';
import { Base } from './Base';

interface Props {
  className?: string;
  children?: React.ReactNode;
  to?: any;
  disabled?: boolean;
  onClick?: () => void;
  resetClasses?: boolean;
}
export const BaseWhite = ({ className, disabled, resetClasses, ...other }: Props) => {
  const classes = resetClasses ? '' : 'f13 fw6 ttu bn white pointer underline';
  const defaultClasses = cx(classes, className);
  return <Base className={defaultClasses} {...other} />;
};
