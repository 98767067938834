import React, { CSSProperties, useEffect, useState } from 'react';
import cx from 'classnames';
import { get } from 'lodash';
import ReactSelect, { components } from 'react-select';
import { IndicatorProps } from 'react-select/lib/components/indicators';
import { ActionMeta, ValueType } from 'react-select/lib/types';
import { callAll } from '../../utils/callAll';
import { Icon } from '../Icons';
import styles from './style.module.css';

export interface Option {
  value: string | number | object;
  label: string;
}

interface Props {
  icon?: string;
  noDropdownIndicator: boolean;
  className?: string;
  options: Option[];
  title?: string;
  errorMessage?: string;
  disabled?: boolean;
  isFormik?: boolean;
  selected?: Option;
  showNoOptions?: boolean;
  locationDropdown?: string;
  isSearchable?: boolean;
  onChange?:
    | (((value: ValueType<Option>, actionMeta: ActionMeta) => void) &
        ((value: ValueType<Option>, action: ActionMeta) => void))
    | undefined;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onInputChange?: typeof ReactSelect.prototype.props.onInputChange;
  name?: string;
  placeholder?: string;
  controlStyle?: any;
  inputValue?: string;
  selectedCategory?: number;
  searchBrand?: string;
  currentYear?: number;
  clearInput?: () => void;
  clearSearchBrand?: () => void;
  isMulti?: boolean;
}

export const Select = ({
  icon,
  noDropdownIndicator,
  options,
  title,
  errorMessage,
  disabled,
  selected,
  onChange,
  onFocus,
  onBlur,
  isFormik,
  showNoOptions,
  locationDropdown = 'body',
  isSearchable = true,
  className,
  name,
  placeholder,
  controlStyle,
  inputValue,
  selectedCategory,
  searchBrand,
  currentYear,
  clearInput,
  clearSearchBrand,
  isMulti = false,
  ...props
}: Props) => {
  const [focus, setFocus] = useState(false);
  const [olderSelectedCategory, setOlderSelectedCategory] = useState<any>(0);
  const [value, setValue] = useState<Option | null>(null);
  let defaultValue = value && selected ? value : selected;

  useEffect(() => {
    setOlderSelectedCategory(selectedCategory);
  }, [selectedCategory]);

  if (isFormik) {
    defaultValue = selected;
  }

  const labelStyle = cx('absolute z-1', {
    [styles.labelUp]: focus || defaultValue,
    [styles.labelDown]: !focus,
    [styles.labelWithIcon]: icon,
  });

  const valueLength = (value && (value as unknown as Option[]).length) || 0;

  const selectStyle: any = {
    control: (provided: CSSProperties, state: any) => ({
      ...provided,
      borderRadius: '8px',
      height: isMulti ? 'auto' : '48px',
      minHeight: isMulti ? '54px' : '48px',
      outline: 'none',
      backgroundColor: state.isDisabled ? '#d7d7d7' : '#fff',
      boxShadow: state.isFocused ? '4px 4px 12px 0 rgba(0, 0, 0, 0.24)' : '',
      overflow: isMulti ? 'hidden' : 'auto',
      border: errorMessage
        ? '2px solid #c143a8'
        : state.isFocused
        ? '2px solid #54575f'
        : '1px solid #919191',
      '&:hover': {
        border: errorMessage
          ? '2px solid #c143a8'
          : state.isFocused
          ? '2px solid #54575f'
          : '1px solid #919191',
      },
      ...controlStyle,
      paddingBottom: isMulti && valueLength > 3 ? `${(valueLength / 3) * 10}px` : '0px',
    }),
    option: (provided: CSSProperties, state: any) => ({
      ...provided,
      color: '#54575f',
      backgroundColor:
        errorMessage && state.isSelected
          ? '#c143a8'
          : state.isSelected || state.isFocused
          ? 'rgba(145, 145, 145, 0.29)'
          : '',
      '&:hover': {
        color: '#54575f',
        backgroundColor: errorMessage ? '#c143a8' : 'rgba(145, 145, 145, 0.29)',
        fontFamily: 'trueno',
      },
    }),
    menu: (provided: CSSProperties) => ({
      ...provided,
      marginTop: '0',
      zIndex: '10',
      fontSize: '14px',
      borderRadius: '8px',
      padding: '0',
      lineHeight: '0',
      height: options && options.length ? 'auto' : 0,
    }),
    singleValue: (provided: CSSProperties) => ({
      ...provided,
      color: '#54575f',
      width: !!noDropdownIndicator && 'calc(100% - 45px)',
    }),
    valueContainer: title
      ? ({ overflow, paddingLeft, position, ...provided }: CSSProperties, isSelected: boolean) => ({
          ...provided,
          transition: isSelected ? 'transform 0.3s ease-in' : '',
          transform: isSelected ? 'translateY(30%)' : '',
          fontSize: isSelected ? '14px' : '16px',
          marginLeft: '1px',
          overflow: icon ? 'inherit' : overflow,
          paddingLeft: icon ? '44px' : paddingLeft,
          position: icon ? 'static' : position,
          marginBottom: isMulti ? '24px' : '1px',
          marginTop: isMulti ? '8px' : '1px',
        })
      : undefined,
    menuList: (provided: CSSProperties) => ({
      ...provided,
      padding: 0,
    }),
    multiValue: (provided: CSSProperties) => ({
      ...provided,
      backgroundColor: 'rgba(62, 152, 20, 0.1)',
      borderRadius: 12,
      border: '1.5px solid #3e9814',
      color: '#3e9814',
      marginBottom: 6,
    }),
    multiValueLabel: (provided: CSSProperties) => ({
      ...provided,
      fontSize: 13,
      color: '#3e9814',
    }),
    multiValueRemove: (provided: CSSProperties) => ({
      ...provided,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 12,
      marginTop: 2,
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
        color: '#3e9814',
      },
    }),
  };
  const defaultOnFocus = () => {
    return setFocus(true);
  };

  const defaultOnBlur = () => {
    return setFocus(false);
  };

  const defaultOnChange = (selectedObject: any) => {
    return setValue(selectedObject as Option);
  };

  const DropdownIndicator = (dropdownIndicatorProps: IndicatorProps<Option>) => {
    return (
      (!noDropdownIndicator && components.DropdownIndicator && (
        <components.DropdownIndicator {...dropdownIndicatorProps} />
      )) ||
      null
    );
  };

  const NoOptionsMessage = () => {
    if (showNoOptions) {
      const classNoOptions = cx('pl8 boulder bg-white b--moon-gray pv8 ph4 ba', styles.noOptions);
      return <div className={classNoOptions}>Sem resultado</div>;
    }
    return null;
  };

  const handleIconRight = () => {
    if (icon) {
      return <Icon name={icon} className={styles.iconLocation} />;
    }
    return null;
  };

  const uid = `ReactSelect_${Math.floor(Math.random() * 1e12)}`;

  const positionDropdown =
    typeof window !== 'undefined' ? document.querySelector(locationDropdown) : null;
  const sectionClass = cx('trueno relative', className);

  if (olderSelectedCategory !== selectedCategory && clearInput) {
    clearInput();

    if (get(value, 'label', null)) {
      setValue(null);

      if (clearSearchBrand) {
        clearSearchBrand();
      }

      defaultOnChange(placeholder);
    }
  }

  return (
    <section className={sectionClass}>
      <label className={labelStyle} htmlFor={uid}>
        {title}
      </label>
      {handleIconRight()}
      <ReactSelect
        menuPortalTarget={positionDropdown && document.body}
        inputId={uid}
        components={{ DropdownIndicator, NoOptionsMessage }}
        isDisabled={disabled}
        styles={selectStyle}
        options={options}
        placeholder={placeholder || ''}
        onFocus={callAll(onFocus, defaultOnFocus)}
        onBlur={callAll(onBlur, defaultOnBlur)}
        onChange={callAll(onChange, defaultOnChange)}
        value={defaultValue}
        tabIndex="0"
        isSearchable={isSearchable}
        name={name}
        inputValue={inputValue}
        isMulti={isMulti}
        {...props}
      />
      {errorMessage && <p className="purpley-pink f11 mv4 ml8">{errorMessage}</p>}
      {errorMessage && <Icon name="SvgIconPurpleError" className="absolute top--8 right--8" />}
    </section>
  );
};

Select.defaultProps = {
  noDropdownIndicator: false,
};
