import React from 'react';
import { Link as InternalLink } from 'gatsby';
import { externalUrlRegex } from '../../utils/functions';

interface Props {
  children?: React.ReactNode;
  to?: any;
  activeClassName?: string;
  className?: string;
  disabled?: boolean | undefined;
  title?: string;
  tabIndex?: number;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  action?: boolean;
  target?: string;
}

export const Base = ({
  children,
  to,
  activeClassName,
  disabled,
  title,
  tabIndex,
  action,
  target,
  ...other
}: Props) => {
  const internal = to && !externalUrlRegex.test(to);

  if (disabled || action) {
    return (
      <span className={activeClassName} {...other}>
        {children}
      </span>
    );
  }

  if (internal && target !== '_blank') {
    return (
      <InternalLink
        to={to}
        activeClassName={activeClassName}
        {...other}
        title={title}
        tabIndex={tabIndex}
      >
        {children}
      </InternalLink>
    );
  }

  const href = to ? { href: to } : {}; // safe check
  return (
    <a {...other} {...href} title={title} tabIndex={tabIndex} target={target}>
      {children}
    </a>
  );
};
