import React from 'react';
import cx from 'classnames';
import { Base } from './Base';
import style from './style.module.css';

interface Props {
  className?: string;
  children?: React.ReactNode;
  to?: any;
  disabled?: boolean;
}
export const BaseGray = ({ className, disabled, ...other }: Props) => {
  const defaultClasses = cx('f14 fw7 abbey link lh-copy pointer', className, {
    [style.transforms]: !disabled,
    alto: disabled,
    'not-allowed': disabled,
  });
  return (
    <div>
      <Base className={defaultClasses} {...other} />
    </div>
  );
};
