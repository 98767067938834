import React from 'react';
import cx from 'classnames';
import { Base } from './Base';
import style from './style.module.css';

interface Props {
  className?: string;
  children?: React.ReactNode;
  to?: any;
  disabled?: boolean;
  color?: string;
  onClick?: (ev?: any) => void;
  resetClass?: boolean;
  title?: string;
  tabIndex?: number;
  onKeyDown?: (event: React.KeyboardEvent) => void;
}
export const DefaultLink = ({
  className,
  disabled,
  resetClass = false,
  title,
  tabIndex,
  ...other
}: Props) => {
  const defaultTachyons = resetClass ? '' : 'f12 red lh-copy underline';
  const defaultClasses = cx(defaultTachyons, className, {
    [style.pinkHover]: !disabled,
    alto: disabled,
    'not-allowed': disabled,
  });
  return (
    <Base
      disabled={false}
      className={defaultClasses}
      title={title}
      tabIndex={tabIndex}
      {...other}
    />
  );
};
