import React from 'react';
import cx from 'classnames';
import { navigate } from 'gatsby';
import { externalUrlRegex } from '../../utils/functions';
import { Icon } from '../Icons';
import { Base } from './Base';
import style from './style.module.css';

interface Props {
  className?: string;
  containter?: string;
  children?: React.ReactNode;
  to?: any;
  disabled?: boolean;
  iconLeft?: string;
  iconRight?: string;
  iconLeftClass?: string;
  iconRightClass?: string;
  onClick?: () => void;
  action?: boolean;
  title: string;
}
export const BaseWithIcons = ({
  className,
  containter,
  disabled,
  iconLeft,
  iconRight,
  iconLeftClass,
  iconRightClass,
  to,
  action,
  ...other
}: Props) => {
  const cls = cx('flex items-center', { 'w-100 justify-between': !containter }, containter);
  const defaultClasses = cx(
    'f13 fw4 link lh-copy pointer ml8 w-100 pt12 pb12 pt8-m pb8-m pt8-l pb8-l',
    {
      abbey: !className,
      [style.transforms]: !disabled,
      alto: disabled,
      'not-allowed': disabled,
    },
    className,
  );
  const boxIconLeft = cx('flex items-center', iconLeftClass);

  const toRedirect = () => {
    if (action || !to) {
      return null;
    }
    const internal = to && !externalUrlRegex.test(to);

    if (internal) {
      return navigate(to);
    }

    return (window.location.href = to);
  };

  const renderIconLeft = () => {
    if (!iconLeft) {
      return null;
    }
    return (
      <div className="flex items-center">
        <Icon name={iconLeft} width={24} height={24} customId={iconLeft} />
      </div>
    );
  };
  const renderIconRight = () => {
    if (!iconRight) {
      return null;
    }
    return (
      <Icon
        name={iconRight}
        width={12}
        height={12}
        customId={iconRight}
        className={iconRightClass}
      />
    );
  };

  return (
    <div className={cls} onClick={toRedirect}>
      <div className={boxIconLeft}>
        {renderIconLeft()}
        <Base className={defaultClasses} action={action} {...other} />
      </div>
      {renderIconRight()}
    </div>
  );
};
