import React from 'react';
import classNames from 'classnames';
import Section from '../../elements/Section';
import styles from './style.module.css';

export const Container = ({ semantic = true, className, ...props }: any) => {
  const containerClasses = classNames(styles.mc, className);
  const containerProps = { className: containerClasses, width: 1, ...props };

  if (!semantic) {
    return <div {...containerProps} />;
  }

  return <Section {...containerProps} />;
};
